<template>
  <div>
    <Error />
  </div>
</template>

<script>
export default {
  components: {
    Error: () => import(/* webpackPrefetch: true */ "./common/Error"),
  },
};
</script>